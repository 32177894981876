import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, setSearchText, searchText, onSearch }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }

      logo {
        logo {
          url
        }
      }

      sociallink {
        linkedin
        facebook
        instagram
      }
    }
  `)

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}>
      <Header onSearch={onSearch} setSearchText={setSearchText} searchText={searchText} logo={data.logo.logo.url} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        {children}
      </div>
      <Footer siteTitle={data.site.siteMetadata?.title || `Title`} socialLink={data.sociallink} logo={data.logo.logo.url} />
    </GoogleReCaptchaProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
