
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions*/

import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import SearchIcon from "../images/search-icon.png"
import { IoIosArrowDropdown } from '@react-icons/all-files/io/IoIosArrowDropdown'

const Header = ({ siteTitle, logo }) => {
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [searchText, setSearchText] = useState("")

  let header;
  let icon;
  let sticky;

  function mobileMenu(e) {
    document.querySelector(".hamburger").classList.toggle("active")
    document
      .querySelector(".cha-header-container__menu")
      .classList.toggle("active")
  }

  const onSearch = e => {
    if (e.key === "Enter") {
      navigate("/search", { state: { searchText } })
    }
  }

  useEffect(() => {
    header = document.getElementById("myHeader");
    sticky = header.offsetTop;

    window.onscroll = function () { myFunction() };
  })

  function myFunction() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  const handleAltLinksDropdown = (id) => {
    header = document.getElementById(id);
    icon = document.getElementById(id + '-icon')
    icon.classList.toggle('dropdown-open-icon')
    header.classList.toggle('dropdown-open')
  }

  return (
    <header className="cha-header-container" id="myHeader">
      <div className="header-containers">
        <div className="logo-container">
          <Link
            to="/"
          >
            <img src={logo} alt={siteTitle} />
          </Link>
        </div>
        <div className="hamburger" onClick={mobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className="cha-header-container__menu">
          <div className="master-link">
            <Link to="/people/" className="people-link nav" >
              People
            </Link>
            <IoIosArrowDropdown className="master-link-dropdown" id="people-link-icon" onClick={() => handleAltLinksDropdown('people-link')} />
            <div className="alt-links" id="people-link">
              <Link to="/people/" className="people-link nav">
                Agents
              </Link>
              <Link to="/management/" className="people-link nav">
                Management
              </Link>
            </div>
          </div>

          <div className="master-link">
            <Link to="/places/" className="nav" >
              Places
            </Link>
            <IoIosArrowDropdown id="places-icon" className="master-link-dropdown" onClick={() => handleAltLinksDropdown('places')} />
            <div className="alt-links" id="places">
              <Link to="/places/" className="nav" >
                SALE
              </Link>
              <Link to="/rentals/" className="nav" >
                RENT
              </Link>
              <Link to="/communities/" className="nav" >
                COMMUNITIES
              </Link>
            </div>
          </div>

          <div className="master-link">
            <Link to="/about/" className="nav" >
              Company
            </Link>
            <IoIosArrowDropdown id="company-icon" className="master-link-dropdown" onClick={() => handleAltLinksDropdown('company')} />
            <div className="alt-links" id="company">
              <Link to="/blog/" className="nav" >
                Blog
              </Link>
            </div>
          </div>

          <div className="cha-header-container__menu__search__container">
            {showSearchInput && <input className="cha-header-container__menu__search__input" placeholder="MLS, address, keyword..." value={searchText} onChange={e => setSearchText(e.target.value)} onKeyPress={onSearch} />}
            <img className="cha-header-container__menu__search__icon" src={SearchIcon} onClick={() => setShowSearchInput(!showSearchInput)} alt="search" />
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
