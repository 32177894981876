import * as Yup from 'yup';

const ContactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string()
      .min(8, 'Too Short!')
      .required('Required'),
    message: Yup.string()
      .min(2, 'Too Short!')
});

export default ContactSchema