/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, location }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  let metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  if (title && defaultTitle) {
    title = `${title} | ${defaultTitle}`
  }
  else if (defaultTitle) {
    title = defaultTitle;
  }

  if (title.length > 70) {
    title = `${title.substr(0, 67)}...`;
  }
  if (metaDescription.length > 300) {
    metaDescription = `${metaDescription.substr(0, 297)}...`
  }

  var locationOrigin = null;
  const siteUrl = site?.siteMetadata?.siteUrl;
  if (siteUrl?.match('\/$')?.index) {
    locationOrigin = siteUrl.substr(0, siteUrl?.match('\/$')?.index);
  }
  else if (siteUrl) {
    locationOrigin = siteUrl;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: "canonical",
          href: `${locationOrigin}${location?.pathname ?? ""}`
        }
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  location: undefined,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  location: PropTypes.object,
}

export default Seo
